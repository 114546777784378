import { ManagedUser, User, ValuationStatus } from './types'
import { AddUserEvents } from './components/management/add-user-modal'

export const ROLES = {
  CA_Admin: 'CA Admin',
  Admin: 'Centre Admin',
  HOC: 'Head of Centre',
  Teacher: 'Teacher',
  AAM: 'Additional Assessment Material',

  QA_Super: 'QA Super',
}

export const isSuperUser = (user: User) => user.roles.includes(ROLES.QA_Super)

export const isCAAdmin = (user: User, hardCheck?: boolean) =>
  user.roles.includes(ROLES.CA_Admin) || (!hardCheck && isSuperUser(user))
export const isAdmin = (user: User) =>
  user.roles.includes(ROLES.Admin) || isSuperUser(user)
export const isHOC = (user: User) =>
  user.roles.includes(ROLES.HOC) || isSuperUser(user)
export const isTeacher = (user: User) =>
  user.roles.includes(ROLES.Teacher) || isSuperUser(user)
export const isAAM = (user: User) =>
  user.roles.includes(ROLES.AAM) || isSuperUser(user)

export const syllabusInProgress = (status: any) =>
  [
    ValuationStatus.NOT_STARTED,
    ValuationStatus.INCOMPLETE,
    ValuationStatus.COMPLETE,
  ].includes(status)

export const BUSINESS_UNITS = {
  CambridgeInternational: 'ci',
  OCR: 'ocr',
}

export const getBusinessUnit = () =>
  process.env.REACT_APP_BU &&
  Object.values(BUSINESS_UNITS).includes(process.env.REACT_APP_BU)
    ? process.env.REACT_APP_BU
    : BUSINESS_UNITS.CambridgeInternational

export const isCambridgeInternational = () =>
  getBusinessUnit() === BUSINESS_UNITS.CambridgeInternational
export const isOCR = () => getBusinessUnit() === BUSINESS_UNITS.OCR

export const calculateAddUserEvent = (
  user: ManagedUser,
  role: string,
  centreId: string
): AddUserEvents | null => {
  const centresLength = user.centres?.length || 0
  if (centresLength === 0) {
    return null
  }

  const containsCurrentCentre = user.centres?.includes(centreId)
  if (containsCurrentCentre && user.role === role) {
    return AddUserEvents.SAME_CENTRE_SAME_ROLE
  }

  if (centresLength === 1 && containsCurrentCentre && user.role !== role) {
    return AddUserEvents.SAME_CENTRE_NEW_ROLE
  }

  if (centresLength > 0 && user.role === role) {
    return AddUserEvents.MULTIPLE_CENTRES_SAME_ROLE
  }

  if (centresLength > 0 && user.role !== role) {
    return AddUserEvents.MULTIPLE_CENTRES_NEW_ROLE
  }

  return null
}

export const isCentreUser = (user: User): boolean => {
  return isTeacher(user) || isHOC(user) || isAdmin(user)
}
